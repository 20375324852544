import fetch from 'isomorphic-fetch'

const handleResponse = function handleResponse(response) {
	// vérification du token
	if (response.status === 204) {
		// no content
		return Promise.resolve();
	}
	if (!response.ok && response.status !== 400) {
		// si la réponse est en erreur sauf erreur de validation, on rejette l'appel
		return Promise.reject(response);
	}
	// dans tous les autres cas, la réponse est traitée en JSON
	return Promise.all([response, response.json()])
		.then(response => {
			const responseStatus = response[0]
			if (responseStatus && responseStatus.status === 200) {
				return response[1]
			}
			return null
		})
}

export const getConfiguration = () =>  {
	return fetch('/configuration/google', {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json; charset=utf-8',
		}
	}).then(handleResponse)
}
