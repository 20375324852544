export enum ProfileType {
	CLIENT = 'client',
	TECHNICIAN = 'technician',
	LANDLORD = 'landlord',
}

export const displayProfileType = (profileType?: ProfileType) => {
	switch (profileType) {
		case ProfileType.CLIENT:
			return 'LOCATAIRE'
		case ProfileType.TECHNICIAN:
			return 'TECHINICIEN'
		case ProfileType.LANDLORD:
			return 'BAILLEUR'
		default:
			return 'INCONNU'
	}
}
