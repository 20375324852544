import React, { FC } from 'react'
import { Button as MUIButton } from '@mui/material'

type ButtonProps = {
	label?: string | JSX.Element
	variant?: 'contained' | 'outlined' | 'text'
	color?: 'inherit'
		| 'primary'
		| 'secondary'
		| 'success'
		| 'error'
		| 'info'
		| 'warning'
	startIcon?: JSX.Element
	endIcon?: JSX.Element
	fullWidth?: boolean,
	height?: string
	onClick?: () => void
	type?: 'button' | 'reset' | 'submit' | undefined
	disable?: boolean
	href?: string
	sx?: any
}

const Button: FC<ButtonProps> = (
	{
		label,
		variant,
		startIcon,
		endIcon,
		fullWidth = true,
		color = 'inherit',
		height = '40px',
		onClick,
		type = 'button',
		disable,
		href,
		sx
	}) => {
	return (
		<MUIButton
			color={color}
			variant={variant}
			disableElevation
			fullWidth={fullWidth}
			startIcon={startIcon}
			endIcon={endIcon}
			sx={{ height: height, justifyContent: 'space-around', borderRadius: '8px', ...sx }}
			onClick={onClick}
			type={type}
			disabled={disable}
			href={href}
		>
			{label}
		</MUIButton>
	)
}

export default Button