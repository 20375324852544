import React, { FC } from 'react'
import { Typography } from '@mui/material'

type TextProps = {
	color?: 'initial'
		| 'inherit'
		| 'primary'
		| 'secondary'
		| 'textPrimary'
		| 'textSecondary'
		| 'error'
	variant?: 'body1'
		| 'body2'
		| 'button'
		| 'caption'
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'h5'
		| 'h6'
		| 'inherit'
		| 'overline'
		| 'subtitle1'
		| 'subtitle2'
	display?: 'initial'
		| 'block'
		| 'inline'
	sx?: any
}

const Text: FC<TextProps> = (
	{
		color = 'textPrimary',
		variant = 'inherit',
		display = 'initial',
		sx,
		children
	}) => {
	return (
		<Typography sx={sx} display={display} color={color} variant={variant}>{children}</Typography>
	)
}

export default Text