import React, { FC, useContext } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Muted } from '../../config/theme'
import { ThemeContext } from '../../config/context/ThemeContext'
import { Stack } from '@mui/material'

const useStyles = makeStyles(() =>
	createStyles({
		barContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'column',
			alignItems: 'center',
			padding: '20px',
			borderTopLeftRadius: '12px',
			borderTopRightRadius: '12px',
			boxShadow: ' 0px -2px 12px rgba(0, 0, 0, 0.07), 0px -0.835552px 5.01331px rgba(0, 0, 0, 0.0503198), 0px -0.446726px 2.68036px rgba(0, 0, 0, 0.0417275), 0px -0.250431px 1.50259px rgba(0, 0, 0, 0.035), 0px -0.133002px 0.798012px rgba(0, 0, 0, 0.0282725), 0px -0.0553451px 0.332071px rgba(0, 0, 0, 0.0196802)'
		}
	})
)

const BottomBar: FC = ({ children }) => {
	const classes = useStyles()
	const { useColorModeValue } = useContext(ThemeContext)

	const bgColor = useColorModeValue(Muted.Light.BGPrimary, Muted.Dark.BGPrimary)

	return (
		<Stack
			className={classes.barContainer}
			bgcolor={bgColor}
		>
			{children}
		</Stack>
	)
}

export default BottomBar