import React from 'react'
import { Field } from 'react-final-form'
import { Stack, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import Text from './Text'
import { useIntl } from 'react-intl'
import { styled } from "@mui/material/styles"

type DatePickerInputProps = {
	name: string
	label: string
}

const StyledDatePicker = styled(DatePicker)({
	"& .MuiCalendarPicker-root div[role='presentation']": {
		fontSize: "19px"
	}
})

const DatePickerInput: React.FC<DatePickerInputProps> = ({ name, label }) => {
	const intl = useIntl()

	return (
		<Field name={name}>
			{({ input, meta }) => (
				<Stack direction="column" spacing={1}>
					<Text variant="subtitle1">{label}</Text>
					<StyledDatePicker
						inputFormat="dd/MM/yyyy"
						value={input.value}
						onChange={input.onChange}
						toolbarTitle={
							<Text variant="subtitle1">{intl.formatMessage({ id: 'form.datePickerTitle' })}</Text>
						}
						InputProps={{ sx: { fontSize: '13px', fontWeight: 400, height: '40px', borderRadius: '8px' } }}
						renderInput={(props) =>
							<TextField {...props} placeholder="-" required={false} error={false} />
						}
					/>
				</Stack>
			)}
		</Field>
	)
}

export default DatePickerInput
