import React, { useCallback, useMemo, useState } from 'react'
import { UserContext } from './UserContext'

const UserProvider: React.FC = ({ children }) => {
	const [mustUser, setMustUser] = useState<MustUser>()

	const setUser = useCallback((user: MustUser) => setMustUser(user), [setMustUser])

	const contextValue = useMemo(() => ({ mustUser, setUser }), [mustUser, setUser])

	return (
		<UserContext.Provider value={contextValue}>
			{children}
		</UserContext.Provider>
	)
}

export default UserProvider
