import React, { useCallback, useMemo, useState } from 'react'
import { ToastContext } from './ToastContext'
import { Alert, Snackbar } from '@mui/material'
import { FormattedMessage } from 'react-intl'

type ToastType = 'success' | 'error' | 'warning' | 'info'

const ToastProvider: React.FC = ({ children }) => {
	const [openToast, setOpenToast] = useState(false)
	const [toastType, setToastType] = useState<ToastType>('success')
	const [toastMessage, setToastMessage] = useState<string>('')

	const addToast = useCallback((type: ToastType, message: string) => {
		setToastType(type)
		setToastMessage(message)
		setOpenToast(true)
	}, [])

	const handleClose = () => setOpenToast(false)

	const contextValue = useMemo(() => ({ addToast }), [addToast])

	return (
		<ToastContext.Provider value={contextValue}>
			<Snackbar
				open={openToast}
				anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
				autoHideDuration={6000}
				onClose={handleClose}
			>
				<Alert severity={toastType} onClose={handleClose}>
					<FormattedMessage id={toastMessage} />
				</Alert>
			</Snackbar>
			{children}
		</ToastContext.Provider>
	)
}

export default ToastProvider
