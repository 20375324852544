export type os = 'Mac OS' | 'iOS' | 'Windows' | 'Android' | 'Linux' | null

export const getOS = (): os => {
	const userAgent = window.navigator.userAgent,
		platform = window.navigator.platform,
		macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
		windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
		iosPlatforms = ['iPhone', 'iPad', 'iPod']
	let os: os = null

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'Mac OS'
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS'
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows'
	} else if (/Android/.test(userAgent)) {
		os = 'Android'
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux'
	}

	return os
}

const getPWADisplayMode = (): 'browser' | 'twa' | 'standalone' => {
	const isStandalone = window.matchMedia('(display-mode: standalone)').matches
	if (document.referrer.startsWith('android-app://')) {
		return 'twa'
	//@ts-ignore
	} else if (navigator.standalone || isStandalone) {
		return 'standalone'
	}
	return 'browser'
}

export const isOnBrower = () => getPWADisplayMode() === 'browser'
