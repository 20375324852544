import React from 'react'
import { Field } from 'react-final-form'
import { Autocomplete, createFilterOptions, FormControl, Stack, TextField } from '@mui/material'
import ErrorMessage from './ErrorMessage'
import Text from './Text'
import { styled } from '@mui/material/styles'

type SelectInputProps = {
	name: string
	validate?: any
	label?: string
	selectOptions: SelectOption[]
	disabled?: boolean,
	onChange?: any
	values: Record<string, any>
}

const StyledAutocomplete = styled(Autocomplete)({
	'& .MuiAutocomplete-inputRoot': {
		padding: '0px',
		fontSize: '13px'
	}
})


const AutocompleteSelectInput: React.FC<SelectInputProps> = (
	{
		name,
		validate,
		selectOptions,
		label,
		disabled,
		values
	}) => {

	const filterOptions = createFilterOptions({
		ignoreAccents: true,
		ignoreCase: true,
		limit: 100,
		matchFrom: 'any'
	})

	const Option = styled('li')({
		fontSize: 13
	})

	// @ts-ignore
	const seletedOption = values[name] ? selectOptions.find(o => o.value === values[name].value) : undefined
	const [inputValue, setInputValue] = React.useState(seletedOption ? seletedOption.label : '')

	return (
		<Field name={name} validate={validate}>
			{({ input, meta }) => {

				const getSelectedOption = () => {
					if (selectOptions.length === 1) {
						handleOnChange(selectOptions[0])
						return selectOptions[0]
					} else if (input.value) {
						var select = selectOptions.find(o => o.value === input.value)
						return select
					}
				}

				const handleOnChange = ((newValue: any) => {
					if (selectOptions.length === 1) {
						input.onChange(selectOptions[0].value)
						setInputValue(selectOptions[0].label)
					} else {
						input.onChange(newValue ? newValue.value : '')
					}
				})

				return (
					<Stack direction="column" spacing={1}>
						<Text variant="subtitle1">{label}</Text>
						<FormControl sx={{ height: '40px' }} size="small">
							<StyledAutocomplete
								noOptionsText={'Aucun résultat'}
								id={name}
								value={getSelectedOption()}
								inputValue={inputValue}
								filterOptions={filterOptions}
								disabled={disabled}
								readOnly={selectOptions.length < 2}
								color="primary"
								sx={{ height: '40px', borderRadius: '8px', padding: '0 ! important' }}
								onInputChange={(e, newInputValue: any) => {
									if (selectOptions.length !== 1) {
										setInputValue(newInputValue)
									}
								}}
								onChange={(e, newValue: any) => {
									handleOnChange(newValue)
								}}
								options={selectOptions.sort((a, b) => -b.label.localeCompare(a.label))}
								getOptionLabel={(option: any) => option.label}
								isOptionEqualToValue={(option: any, value: any) => value && option.value === value.value}
								renderInput={(params) => (
									<TextField
										{...params}
										inputProps={{
											...params.inputProps,
											autoComplete: 'new-password' // disable autocomplete and autofill
										}}
									/>
								)}
								renderOption={(props2, option: any) => (
									<Option {...props2}>{option.label}</Option>
								)}
							/>
						</FormControl>
						<ErrorMessage disable={!(meta.error && meta.touched)} error={meta.error} />
					</Stack>
				)
			}}
		</Field>
	)
}

export default AutocompleteSelectInput
