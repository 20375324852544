import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from 'react'
import Popin from '../../../components/Popin'
import { Box, Button } from '@mui/material'
import {postScanInfo} from '../services/scanApi'
import {APP_ROUTES} from '../../../containers/routesConstants'
import {useHistory} from 'react-router-dom'
import {ToastContext} from '../../toast/ToastContext'
import {CodeContext} from '../services/context/CodeContext'
import {UserContext} from '../../login/services/context/UserContext'
import {ToastType} from '../../toast/toastConstants'
import {ProfileType} from '../../login/services/userConstants'
import {ErrorConstants} from '../../../components/errorConstants'
import {updateQrCodeEquipment} from '../../intervention/services/equipmentApi'
import {QrReader} from 'react-qr-reader'
import {updateQrCodeSpace} from "../../addingSpace/services/addingSpaceApi";

type ScannerProps = {
	openModal: boolean
	setOpenModal: Dispatch<SetStateAction<boolean>>
	setIsSearching?: Dispatch<SetStateAction<boolean>>
	isUpdatingCode?: boolean
	setCanReplaceQrCode?: Dispatch<SetStateAction<boolean>>
	equipmentOnly?: boolean
	setOpenEquipmentPopin?: Dispatch<SetStateAction<boolean>>
}

const Scanner: React.FC<ScannerProps> = (
	{
		openModal,
		setOpenModal,
		setIsSearching,
		isUpdatingCode,
		setCanReplaceQrCode,
		equipmentOnly,
		setOpenEquipmentPopin
	}) => {
	const history = useHistory()

	const { addToast } = useContext(ToastContext)
	const { setHouseInfo, setCode, setEquipmentInfo, code } = useContext(CodeContext)
	const { mustUser } = useContext(UserContext)

	const isUserTechnician = useMemo(() => mustUser?.type === ProfileType.TECHNICIAN, [mustUser])

	const [data, setData] = useState<string>('')

	const onSubmitUpdating = (value: string) => {
		if (value && code) {
			setIsSearching && setIsSearching(true)
			const updatePromise = equipmentOnly ? updateQrCodeEquipment({ qrCode: code, newQrCode: value }) : updateQrCodeSpace({ qrCode: code, newQrCode: value })
			updatePromise
				.then(() => {
					setCanReplaceQrCode && setCanReplaceQrCode(false)
					addToast(ToastType.SUCCESS, 'visit.replaceQrCode.success')
				})
				.catch((err) => {
					if (err.message === ErrorConstants.QRCODE_ALREADY_USED) {
						addToast(ToastType.ERROR, 'global.errors.QRCODE_ALREADY_USED')
					} else {
						addToast(ToastType.ERROR, 'global.errors.occurred')
					}
				})
				.finally(() => {
					setOpenModal(false)
					setIsSearching && setIsSearching(false)
				})
		}
	}

	const onSubmitScanning = (value: string) => {
		if (mustUser) {
			setIsSearching && setIsSearching(true)
			setCode(value)
			postScanInfo({ userType: mustUser.type, qrCode: value, spaceId: mustUser.id.toString(), equipmentOnly: equipmentOnly || false })
				.then((res) => {
					const { equipment, houseInfo } = res
					if (isUserTechnician) {
						if (equipmentOnly) {
							if (houseInfo && equipment) {
								setEquipmentInfo(res.equipment || undefined)
								setHouseInfo(res.houseInfo || undefined)
								history.push(APP_ROUTES.FOUND, { isEquipment: true })
							} else if (isUserTechnician && !equipment) {
								history.push(APP_ROUTES.ADD_EQUIPMENT)
							}
						} else {
							if (houseInfo && !equipment) {
								setHouseInfo(res.houseInfo || undefined)
								setEquipmentInfo(undefined)
								history.push(isUserTechnician ? APP_ROUTES.FOUND : APP_ROUTES.VISIT, { isLinked: false })
							} else if (houseInfo && equipment) {
								setEquipmentInfo(res.equipment || undefined)
								setHouseInfo(res.houseInfo || undefined)
								setOpenEquipmentPopin && setOpenEquipmentPopin(true)
							} else if (!houseInfo && !equipment) {
								history.push(APP_ROUTES.ADD_HOUSE)
							}
						}
					} else {
						if (houseInfo && !equipment) {
							setHouseInfo(res.houseInfo || undefined)
							setEquipmentInfo(undefined)
							history.push(APP_ROUTES.CLIENT_FOUND)
						} else if (!houseInfo && equipment) {
							setHouseInfo( undefined)
							setEquipmentInfo(res.equipment || undefined)
							history.push(APP_ROUTES.CLIENT_FOUND)
						} else if (houseInfo && equipment) {
							setEquipmentInfo(res.equipment || undefined)
							setHouseInfo(res.houseInfo || undefined)
							history.push(APP_ROUTES.CLIENT_FOUND)
						}
					}
				})
				.catch((error) => {
					if (Object.keys(ErrorConstants).includes(error.message)) {
						addToast(ToastType.ERROR, `global.errors.${error.message}`)
					} else {
						addToast(ToastType.ERROR, 'global.errors.occurred')
					}
				})
				.finally(() => {
					setIsSearching && setIsSearching(false)
				})
		}
	}

	useEffect(() => {
		if (data) {
			isUpdatingCode ? onSubmitUpdating(data) : onSubmitScanning(data)
		}
		return () => setData('')
	}, [data, isUpdatingCode])

	return (
		<Popin modalOpen={openModal} setModalOpen={setOpenModal}>
			<Box width={window.innerWidth - 40}>
				<QrReader
					onResult={(result, error) => {
						if (!!result) {
							setData(result.toString().split('id=')[1])
						}

						if (!!error) {
							console.info(error)
						}
					}}
					scanDelay={1}
					containerStyle={{ width: '100%' }}
					ViewFinder={() =>
						<Box sx={{
							width: window.innerWidth - 40,
							height: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							position: 'absolute',
							top: 0,
							left: 0
						}}>
							<Box sx={{
								zIndex: 1,
								boxSizing: 'border-box',
								border: 'solid 6px #fff',
								width: '100px',
								height: '100px',
								borderRadius: '5%'
							}} />
						</Box>
					}
					constraints={{ facingMode: 'environment' }}
				/>
			</Box>
		</Popin>
	)
}

export default Scanner
