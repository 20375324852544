const checkErrors = ([json, ok]: [any, boolean]) => {
	if (!ok) {
		return Promise.reject(json)
	}

	return json
}

/**
 * Gestion de la réponse
 * @param response
 */
export const handleResponse = (response: ApiResponse) => {
	if (response.status === 204) {
		return Promise.resolve()
	}
	const isJsonResponse = response.headers.get('content-type')?.indexOf('application/json') >= 0
	if (isJsonResponse) {
		return Promise.all([response.json(), response.ok])
			.then(checkErrors)
	}
	if (response.ok) {
		return response.text()
	}
	return Promise.reject(response)
}

export const fetchFactory = (path: string, options?: RequestInit): Promise<any> => {
	const token = window.localStorage.getItem('token') || null
	const defaultHeaders: any = {
		Accept: 'application/json',
		'Content-Type': 'application/json; charset=utf-8'
	}
	if (token) {
		defaultHeaders.Authorization = token
	}

	const newOptions = {
		method: options?.method || 'GET',
		body: options?.body || null,
		headers: {
			...defaultHeaders,
			...(options && options.headers)
		}
	}

	return fetch(`${process.env.REACT_APP_SERVER_URL}${path}`, newOptions).then(handleResponse)
}