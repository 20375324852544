import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Box } from '@material-ui/core'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import Button from '../../../components/Button'
import { getUser as getUserAction, logUser as logUserAction } from '../services/loginService/loginActions'
import { fetchUser as fetchUserApi, login as loginApi, storeLocalUser as storeLocalUserApi } from '../services/loginService/loginApi'
import FormInput from '../../../components/reduxForm/fields/FormInput'

//todo intl
const validateLogin = (value) => value ? undefined : 'L\'identifiant est obligatoire'
const validatePassword = (value) => value ? undefined : 'Le mot de passe est obligatoire'

class LoginForm extends Component {

	//todo window href
	envoyerFrom = (values, dispatch) => {
		const { nextPathname, setLoading } = this.props
		setLoading(true)
		return new Promise((resolve, reject) => loginApi(values)
			.then((json) => {
				if (json) {
					dispatch(logUserAction(json.token))
					storeLocalUserApi(json)
					resolve(json.token)
				} else {
					reject({
						password: 'Vos identifiants sont incorrects'
					})
				}
			})
			.catch(e => {
				reject({
					login: 'Connexion impossible'
				})
			})
		).then((token) => fetchUserApi(token)
		).then((user) => {
			dispatch(getUserAction(user))
		}).then(() => {
			if (nextPathname) {
				window.location.href = nextPathname
			} else {
				window.location.href = '/'
			}
		}).catch(e => {
			setLoading(false)
			throw new SubmissionError(e)
		})
	}

	render() {
		const { handleSubmit, pristine, submitting } = this.props

		return (
			<form onSubmit={handleSubmit(this.envoyerFrom)} autoComplete="off">
				<Box marginBottom="32px" width="100%">
					<Field
						component={FormInput}
						label="Email"
						name="login"
						fullWidth
						validate={[validateLogin]}
					/>

				</Box>
				<Box marginBottom="64px" width="100%">
					<Field
						component={FormInput}
						type="password"
						label="Mot de passe"
						name="password"
						fullWidth
						validate={[validatePassword]}
					/>
				</Box>

				<Button
					type="submit"
					label="Se connecter"
					color="primary"
					variant="contained"
					disable={pristine || submitting}
				/>

			</form>
		)
	}
}

LoginForm.propTypes = {
	nextPathname: PropTypes.string,
	submitting: PropTypes.bool,
	handleSubmit: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
	user: state.user
})

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: 'loginForm'
	})
)(LoginForm)
