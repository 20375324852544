import React, { useContext, useMemo, useState } from 'react'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import DefaultLogo from '../../assets/images/default_logo.png'
import { ThemeContext } from '../../config/context/ThemeContext'
import { Muted } from '../../config/theme'
import Button from '../Button'
import Text from '../Text'
import { logOut } from '../../modules/login/services/loginService/loginActions'
import { useIntl } from 'react-intl'
import {Redirect, useHistory} from 'react-router'
import { useLocation } from 'react-router-dom'
import { Box, Divider, Stack, Switch } from '@mui/material'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {APP_ROUTES} from "../../containers/routesConstants";

const TopBar = ({
	                // @ts-ignore
	                logOut
                }) => {
	const { useColorModeValue, theme, toggleTheme, themeValues } = useContext(ThemeContext)
	const intl = useIntl()
	const location = useLocation()
	const history = useHistory()

	const [redirectToHome] = useState<boolean>(false)
	// Drawer
	const [openDrawer, setOpenDrawer] = useState<boolean>(false)

	// Colors
	const bgColor = useColorModeValue(Muted.Light.BGPrimary, Muted.Dark.BGSecondary)
	const iconColor = themeValues.Muted.Content3
	const borderColor = themeValues.Muted.Border
	const iconBgColor = themeValues.Muted.BGSecondary
	const buttonBgColor = useColorModeValue(Muted.Light.BGSecondary, Muted.Dark.BGPrimary)
	const buttonTextColor = themeValues.Muted.SingleTone

	const styles = {
		paper: {
			borderBottomLeftRadius: '12px',
			width: '100%',
			borderBottomRightRadius: '12px',
		}
	}

	const handleLogout = () => {
		logOut()
	}

	// i18n
	const consultLegalMessage = { id: 'global.buttons.consultLegal' }
	const logoutMessage = { id: 'global.buttons.logout' }
	const activateDarkModeMessage = { id: 'topBar.activateDarkMode' }
	const mailSupport = { id: 'global.buttons.mailSupport' }

	const renderMainContent = useMemo(() => {
			return redirectToHome && location.pathname !== '/' ? <Redirect to={APP_ROUTES.HOME} /> :
				<Stack
					direction="column"
					position="fixed"
					zIndex={10}
					width="100%"
					mb="20px"
					boxShadow="0px 4px 17px rgba(0, 0, 0, 0.06), 0px 1.6711px 7.10219px rgba(0, 0, 0, 0.0431313), 0px 0.893452px 3.79717px rgba(0, 0, 0, 0.0357664), 0px 0.500862px 2.12866px rgba(0, 0, 0, 0.03), 0px 0.266004px 1.13052px rgba(0, 0, 0, 0.0242336), 0px 0.11069px 0.470434px rgba(0, 0, 0, 0.0168687)"
					sx={{ bgcolor: bgColor, ...styles.paper }}
				>
					<Stack direction="row" alignItems="center" justifyContent="space-between" p="20px">
						<Button
							onClick={() => history.push(APP_ROUTES.HOME)}
							label={<HomeOutlinedIcon />}
							variant="contained"
							fullWidth={false}
							color="secondary"
							height="48px"
						/>
						<img src={DefaultLogo} alt="default logo" width="82px" height="24px" />
						<Button
							onClick={() => setOpenDrawer(prevState => !prevState)}
							label={<SettingsOutlinedIcon />}
							variant="outlined"
							fullWidth={false}
							height="48px"
							sx={{
								bgcolor: openDrawer ? iconBgColor : bgColor,
								color: iconColor,
								borderColor: openDrawer ? borderColor : bgColor
							}}
						/>
					</Stack>
					{openDrawer && (
						<Box
							position="absolute"
							top="80px"
							bgcolor={bgColor}
							sx={styles.paper}
							boxShadow="0px 4px 17px rgba(0, 0, 0, 0.06), 0px 1.6711px 7.10219px rgba(0, 0, 0, 0.0431313), 0px 0.893452px 3.79717px rgba(0, 0, 0, 0.0357664), 0px 0.500862px 2.12866px rgba(0, 0, 0, 0.03), 0px 0.266004px 1.13052px rgba(0, 0, 0, 0.0242336), 0px 0.11069px 0.470434px rgba(0, 0, 0, 0.0168687)"
						>
							<Box flexDirection="column" margin="20px">
								<Box width="100%" mb="12px">
									<Button
										onClick={() => history.push(APP_ROUTES.MENTION_LEGAL)}
										variant="outlined"
										label={intl.formatMessage(consultLegalMessage)}
										sx={{
											bgcolor: buttonBgColor,
											borderColor: bgColor,
											color: buttonTextColor
										}}
									/>
								</Box>
								<Box width="100%" mb="12px">
									<Button
										onClick={handleLogout}
										variant="outlined"
										label={intl.formatMessage(logoutMessage)}
										sx={{
											bgcolor: buttonBgColor,
											borderColor: bgColor,
											color: buttonTextColor
										}}
									/>
								</Box>
								<Box width="100%">
									<Button
										href={'mailto:dsi@must-group.fr'}
										variant="contained"
										label={intl.formatMessage(mailSupport)}
										sx={{
											bgcolor: buttonBgColor,
											borderColor: bgColor,
											color: buttonTextColor
										}}
									/>
								</Box>
							</Box>
							<Divider />
							<Box margin="20px" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
								<Text variant="h6">{intl.formatMessage(activateDarkModeMessage)}</Text>
								<Switch color="primary" checked={theme === 'dark'} onChange={toggleTheme} />
							</Box>
						</Box>
					)}
				</Stack>
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [redirectToHome, location.pathname, openDrawer, theme])

	return (
		<>
			{renderMainContent}
		</>
	)
}

TopBar.propTypes = {
	logOut: PropTypes.func.isRequired
}

const actions = {
	logOut
}

export default connect(null, actions)(TopBar)
