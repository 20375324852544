import React, { FC } from 'react'
import Text from './Text'
import { Box, Stack } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

type ErrorMessageProps = {
	disable: boolean
	error: string
}

const ErrorMessage: FC<ErrorMessageProps> = ({ disable, error }) => {
	return (
		<Box mt="8px">
			{!disable && (
				<Stack direction="row" spacing={1} alignItems="center">
					<ErrorOutlineIcon color="error" />
					<Text variant="subtitle1" color="error">{error}</Text>
				</Stack>
			)}
		</Box>
	)
}

export default ErrorMessage