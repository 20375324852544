import React, { FC, useContext } from 'react'
import { Box, Stack } from '@mui/material'
import TopBar from './TopBar'
import BottomBar from './BottomBar'
import { ThemeContext } from '../../config/context/ThemeContext'
import Button from '../Button'

type PageContainerProps = {
	center?: boolean
	useDefaultBgColor?: boolean
	useTopBar?: boolean
	mainButtonProps?: BottomButtonProps
	secondaryButtonProps?: BottomButtonProps
}

const PageContainer: FC<PageContainerProps> = (
	{
		children,
		center,
		useDefaultBgColor,
		useTopBar = true,
		mainButtonProps,
		secondaryButtonProps
	}) => {
	const { themeValues } = useContext(ThemeContext)

	// Colors
	const bgSecondaryColor = themeValues.Muted.BGSecondary
	const bgPrimaryColor = themeValues.Muted.BGPrimary
	const buttonTextColor = themeValues.Muted.SingleTone
	const borderColor = themeValues.Muted.Border

	return (
		<Stack
			justifyContent={center ? 'center' : 'space-between'}
			alignItems={center ? 'center' : 'inherit'}
			bgcolor={!useDefaultBgColor ? bgSecondaryColor : bgPrimaryColor}
			overflow="hidden"
			sx={{ overflowY: 'auto' }}
		>
			{useTopBar && <TopBar />}
			<Stack justifyContent="space-between" height="100%" minHeight={window.innerHeight}>
				<Box flex={1} pt="108px" >
					{children}
				</Box>
				{(mainButtonProps || secondaryButtonProps) &&
					<BottomBar>
						{mainButtonProps && !mainButtonProps.notDisplay &&
							<Box width="100%" marginBottom="12px">
								<Button
									onClick={mainButtonProps.onClick}
									color="primary"
									variant="contained"
									label={mainButtonProps.label}
									startIcon={mainButtonProps.startIcon}
									endIcon={mainButtonProps.endIcon}
									height={mainButtonProps.height}
									disable={mainButtonProps.disabled}
								/>
							</Box>
						}
						{secondaryButtonProps && !secondaryButtonProps.notDisplay &&
							<Box width="100%">
								<Button
									onClick={secondaryButtonProps.onClick}
									variant="outlined"
									label={secondaryButtonProps.label}
									sx={{ bgColor: 'transparent', borderColor, color: buttonTextColor }}
								/>
							</Box>}
					</BottomBar>
				}
			</Stack>
		</Stack>
	)
}

export default PageContainer
