import React, { useContext, useState } from 'react'
import PageContainer from '../../../components/layout/PageContainer'
import Card from '../../../components/layout/Card'
import { Box, Stack } from '@mui/material'
import Button from '../../../components/Button'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Form } from 'react-final-form'
import { HouseFieldNames } from '../services/addingSpaceConstants'
import { CodeContext } from '../../scan/services/context/CodeContext'
import { ThemeContext } from '../../../config/context/ThemeContext'
import { checkSpace, postQrCode } from '../services/addingSpaceApi'
import { APP_ROUTES } from '../../../containers/routesConstants'
import Popin from '../../../components/Popin'
import Text from '../../../components/Text'
import AutocompleteSelectInput from '../../../components/AutocompleteSelectInput'

const AddingHousePage: React.FC = () => {
	const intl = useIntl()
	const history = useHistory()

	const { spaceInfo, code, setHouseInfo } = useContext(CodeContext)
	const { themeValues } = useContext(ThemeContext)

	const [disableButton, setDisableButton] = useState<boolean>(true)
	const [openModal, setOpenModal] = useState<boolean>(false)
	const [values, setValues] = useState<Record<string, any>>()

	//i18n
	const linkCodeMessage = { id: 'global.buttons.linkCode' }
	const cancelMessage = { id: 'global.buttons.cancel' }
	const validateMessage = { id: 'global.buttons.validate' }
	const linkSpaceMessage = { id: 'linkSpace.title' }
	const popinTitleMessage = { id: 'linkSpace.popin.title' }
	const popinTextMessage = { id: 'linkSpace.popin.text' }
	const buildingProgramMessage = { id: `linkSpace.fields.${HouseFieldNames.BUILDING_PROGRAM_NAME}` }
	const buildingNumberMessage = { id: `linkSpace.fields.${HouseFieldNames.BUILDING_NUMBER}` }
	const buildingEntryMessage = { id: `linkSpace.fields.${HouseFieldNames.BUILDING_ENTRY}` }
	const spaceNumberMessage = { id: `linkSpace.fields.${HouseFieldNames.SPACE_NUMBER}` }

	// Colors
	const textColor = themeValues.Muted.Content2

	// Options
	const buildingProgramOptions: SelectOption[] = spaceInfo ? Object.keys(spaceInfo).map(key => ({
		label: key,
		value: key
	})) : []

	const onSubmit = () => {
		if (code && values && values[HouseFieldNames.SPACE_NUMBER]) {
			postQrCode(values[HouseFieldNames.SPACE_NUMBER], code)
				.then((res) => {
					setHouseInfo(res)
					history.push(APP_ROUTES.FOUND, { isLinked: true })
				})
		}
	}

	return (
		<PageContainer
			mainButtonProps={{
				onClick: () => {
					if (values && values[HouseFieldNames.SPACE_NUMBER]) {
						checkSpace(values[HouseFieldNames.SPACE_NUMBER])
							.then(res => {
								if (res) {
									setOpenModal(true)
								} else {
									onSubmit()
								}
							})
					}
				},
				label: intl.formatMessage(linkCodeMessage),
				disabled: disableButton
			}}
			secondaryButtonProps={{
				onClick: () => history.push(APP_ROUTES.HOME),
				label: intl.formatMessage(cancelMessage)
			}}
		>
			<Card title={intl.formatMessage(linkSpaceMessage)} height="calc(100vh - 300px)">
				<Form
					onSubmit={onSubmit}
					render={({ handleSubmit, dirtyFields, values }) => {
						// Options
						const buildingNumberOptions: SelectOption[] = spaceInfo
						&& values[HouseFieldNames.BUILDING_PROGRAM_NAME] ?
							Object.keys(spaceInfo[values[HouseFieldNames.BUILDING_PROGRAM_NAME]]['buildings']).map(key => ({
								label: key,
								value: key
							})) : []
						const buildingEntryOptions: SelectOption[] = spaceInfo
						&& values[HouseFieldNames.BUILDING_PROGRAM_NAME]
						&& values[HouseFieldNames.BUILDING_NUMBER]
						&& spaceInfo[values[HouseFieldNames.BUILDING_PROGRAM_NAME]]['buildings'][values[HouseFieldNames.BUILDING_NUMBER]] ?
							Object.keys(spaceInfo[values[HouseFieldNames.BUILDING_PROGRAM_NAME]]['buildings'][values[HouseFieldNames.BUILDING_NUMBER]]['entries']).map(key => ({
								label: key,
								value: key
							})) : []
						const spaceNumberOptions: SelectOption[] = spaceInfo
						&& values[HouseFieldNames.BUILDING_PROGRAM_NAME]
						&& values[HouseFieldNames.BUILDING_NUMBER]
						&& values[HouseFieldNames.BUILDING_ENTRY]
						&& spaceInfo[values[HouseFieldNames.BUILDING_PROGRAM_NAME]]['buildings'][values[HouseFieldNames.BUILDING_NUMBER]]
						&& spaceInfo[values[HouseFieldNames.BUILDING_PROGRAM_NAME]]['buildings'][values[HouseFieldNames.BUILDING_NUMBER]]['entries'][values[HouseFieldNames.BUILDING_ENTRY]] ?
							Object.keys(spaceInfo[values[HouseFieldNames.BUILDING_PROGRAM_NAME]]['buildings'][values[HouseFieldNames.BUILDING_NUMBER]]['entries'][values[HouseFieldNames.BUILDING_ENTRY]]).map(key => ({
								label: key,
								value: spaceInfo[values[HouseFieldNames.BUILDING_PROGRAM_NAME]]['buildings'][values[HouseFieldNames.BUILDING_NUMBER]]['entries'][values[HouseFieldNames.BUILDING_ENTRY]][key]
							})) : []
						const requiredFieldsFilled = values[HouseFieldNames.BUILDING_PROGRAM_NAME] &&
							values[HouseFieldNames.BUILDING_NUMBER] &&
							values[HouseFieldNames.BUILDING_ENTRY] &&
							values[HouseFieldNames.SPACE_NUMBER]

						if (requiredFieldsFilled) {
							setDisableButton(false)
						} else {
							setDisableButton(true)
						}

						setValues(values)

						return (
							<form style={{ width: '100%' }} onSubmit={handleSubmit}>
								<Stack width="100%" spacing={2}>
									<AutocompleteSelectInput
										name={HouseFieldNames.BUILDING_PROGRAM_NAME}
										label={intl.formatMessage(buildingProgramMessage)}
										selectOptions={buildingProgramOptions}
										values={values}
									/>
									<AutocompleteSelectInput
										name={HouseFieldNames.BUILDING_NUMBER}
										label={intl.formatMessage(buildingNumberMessage)}
										selectOptions={buildingNumberOptions}
										disabled={!dirtyFields[HouseFieldNames.BUILDING_PROGRAM_NAME]}
										values={values}
									/>
									<AutocompleteSelectInput
										name={HouseFieldNames.BUILDING_ENTRY}
										label={intl.formatMessage(buildingEntryMessage)}
										selectOptions={buildingEntryOptions}
										disabled={!dirtyFields[HouseFieldNames.BUILDING_NUMBER]}
										values={values}
									/>
									<AutocompleteSelectInput
										name={HouseFieldNames.SPACE_NUMBER}
										label={intl.formatMessage(spaceNumberMessage)}
										selectOptions={spaceNumberOptions}
										disabled={!dirtyFields[HouseFieldNames.BUILDING_ENTRY]}
										values={values}
									/>
								</Stack>
							</form>
						)
					}}
				/>
			</Card>
			<Popin modalOpen={openModal} setModalOpen={setOpenModal} label={intl.formatMessage(popinTitleMessage)}>
				<Stack spacing={2}>
					<Box>
						<Text sx={{ color: textColor }} variant="subtitle1">{intl.formatMessage(popinTextMessage)}</Text>
					</Box>
					<Button
						onClick={onSubmit}
						color="primary"
						variant="contained"
						label={intl.formatMessage(validateMessage)}
					/>
				</Stack>
			</Popin>
		</PageContainer>
	)
}

export default AddingHousePage
