export enum APP_ROUTES {
	LOGIN = '/login',
	LOGOUT = '/logout',
	HOME = '/accueil',
	MENTION_LEGAL = '/mentionLegal',
	FOUND = '/found',
	ADD_HOUSE = '/add-house',
	ADD_EQUIPMENT = '/add-equipment',
	VISIT = '/visit',
	IMPORT = '/import',
	EQUIPMENT = '/equipment',
	INTERVENTION = '/intervention',
	VISIT_END = '/visit-end',
	VISIT_CONFIRMATION = '/visit-confirmation',
	CLIENT_FOUND = '/client-found',
}
