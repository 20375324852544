import React from 'react'
import ReactDOM from 'react-dom'
import { ConnectedRouter, connectRouter, replace, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { intlReducer, Provider } from 'react-intl-redux'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { logOut, getUser } from './modules/login/services/loginService/loginActions'
import { getLocalUser, configureStorageMode } from './modules/login/services/loginService/loginApi'
import { fetchUser as fetchUserApi } from './modules/login/services/loginService/loginApi'
import Root from './containers/Root'
import { reducer as formReducer } from 'redux-form'
import reducers from './config/reducers'
import messages from './config/messages'
import thunk from 'redux-thunk'
import ThemeProvider from './config/context/ThemeProvider'
import UserProvider from './modules/login/services/context/UserProvider'
import CodeProvider from './modules/scan/services/context/CodeProvider'
import ToastProvider from './modules/toast/ToastProvider'

interface IUser {
	token: string,
	authenticated: boolean
}

// Création du router
const history = createBrowserHistory()

// Cette méthode permet d'enfermer tout le code à exécuter après les vérifications sur le login
const reactInit = (user?: IUser) => {
	const formats = {
		date: {
			datetime: {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric'
			}
		}
	}
	// Initialisation de tous les enhancers utilisés avec Redux
	const enhancers = [
		applyMiddleware(
			thunk,
			routerMiddleware(history)
		)
	]

	// Création du store de redux
	let store: any = createStore(
		combineReducers({
			...reducers,
			router: connectRouter(history),
			intl: intlReducer,
			form: formReducer
		}), {
			user,
			intl: {
				locale: 'fr',
				messages: {
					...messages
				},
				formats: {
					...formats
				}
			}
		},
		compose(...enhancers)
	)

	ReactDOM.render(
		<React.StrictMode>
			<ThemeProvider>
				<Provider store={store}>
					<UserProvider>
						<CodeProvider>
							<ConnectedRouter history={history}>
								<ToastProvider>
									<Root />
								</ToastProvider>
							</ConnectedRouter>
						</CodeProvider>
					</UserProvider>
				</Provider>
			</ThemeProvider>
		</React.StrictMode>,
		document.getElementById('root')
	)

	// On gère la déconnexion multi-onglets pour le local storage
	window.addEventListener('storage', () => {
		const token = getLocalUser()
		if (token) {
			fetchUserApi(token)
				.then((user: IUser) => {
					if (user) {
						user.token = token
						user.authenticated = true
					}
					store.dispatch(getUser(user))
					store.dispatch(replace('/'))
				})
				.catch(() => {
					store.dispatch(logOut())
				})
		} else {
			store.dispatch(logOut())
		}
	})
}

const token = window.localStorage.getItem('token')
configureStorageMode()
if (token) {
	fetchUserApi(token).then((user: IUser) => {
		if (user) {
			user.token = token
			user.authenticated = true
		}
		reactInit(user)
	}).catch((error: Error) => {
		reactInit()
	})
} else {
	reactInit()
}
