import React, { useContext } from 'react'
import Button from '../../../components/Button'
import { ArrowForward } from '@mui/icons-material'
import { ThemeContext } from '../../../config/context/ThemeContext'
import { Stack } from '@mui/material'
import Text from '../../../components/Text'
import { useIntl } from 'react-intl'

type InterventionProps = {
	onClick: () => void
	data: InterventionData
}

const InterventionCell: React.FC<InterventionProps> = (
	{
		onClick,
		data
	}) => {
	const intl = useIntl()
	const { themeValues } = useContext(ThemeContext)

	// Colors
	const iconColor = themeValues.Muted.Content3
	const buttonTextColor = themeValues.Muted.SingleTone
	const borderColor = themeValues.Muted.Border

	// i18n
	const interventionTypeMessage = { id: `intervention.fields.selectLabel.${data.interventionType}` }

	return (
		<Button
			onClick={onClick}
			variant="outlined"
			height="auto"
			label={
				<Stack
					px="8px"
					direction="row"
					width="100%"
					justifyContent="space-between"
					alignItems="center"
				>
					<Stack alignItems="flex-start">
						<Text variant="h6">{data.type}</Text>
						<Text variant="subtitle1">{`${data.brand} - ${intl.formatMessage(interventionTypeMessage)}`}</Text>
					</Stack>
					<ArrowForward sx={{ color: iconColor }} />
				</Stack>
			}
			sx={{ bgColor: 'transparent', borderColor, color: buttonTextColor}}
		/>
	)
}

export default InterventionCell
