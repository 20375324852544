import { createContext } from 'react'

type UserContextProps = {
	mustUser?: MustUser
	setUser: (user: MustUser) => void
}

const context: UserContextProps = {
	mustUser: undefined,
	setUser: () => {
	}
}

export const UserContext = createContext(context)