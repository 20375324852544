export const Primary = {
	Light: {
		Base: '#01884d',
		Hover: '#34a071',
		Pressed: '#016d3e',
		Border: '#ccf1d6',
		Background: '#e5f8eb'
	},
	Dark: {
		Base: '#00a65d',
		Hover: '#33b87d',
		Pressed: '#00854a',
		Border: '#004d2b',
		Background: '#002a17'
	}
}

export const Secondary = {
	Light: {
		Base: '#ac0066',
		Hover: '#bd3385',
		Pressed: '#8a0052',
		Border: '#fbd5eb',
		Background: '#fdeaf6'
	},
	Dark: {
		Base: '#bf0071',
		Hover: '#bd3385',
		Pressed: '#8a0052',
		Border: '#600039',
		Background: '#430028'
	}
}

export const Muted = {
	Light: {
		Content1: '#1c1c1c',
		Content2: '#585757',
		Content3: '#969696',
		Border: '#e8e8e8',
		BGSecondary: '#f7f7f7',
		BGPrimary: '#ffffff'
	},
	Dark: {
		Content1: '#e8e8e8',
		Content2: '#bababa',
		Content3: '#767676',
		Border: '#313131',
		BGSecondary: '#272727',
		BGPrimary: '#1a1a1a'
	}
}

export const typographyConfig = {
	fontFamily: ['Inter', 'sans-serif'].join(','),
	button: {
		textTransform: 'none',
		fontSize: 13,
		fontWeight: 500
	},
	h1: {
		fontSize: 48,
		fontWeight: 500
	},
	h2: {
		fontSize: 36,
		fontWeight: 500
	},
	h3: {
		fontSize: 24,
		fontWeight: 500
	},
	h4: {
		fontSize: 20,
		fontWeight: 500
	},
	h5: {
		fontSize: 16,
		fontWeight: 500
	},
	h6: {
		fontSize: 13,
		fontWeight: 500
	},
	body1: {
		fontSize: 20,
		fontWeight: 400
	},
	body2: {
		fontSize: 16,
		fontWeight: 400
	},
	subtitle1: {
		fontSize: 13,
		fontWeight: 400
	},
	subtitle2: {
		fontSize: 10,
		fontWeight: 400
	},
	caption: {
		fontSize: 10,
		fontWeight: 600
	}
}

export const colorConfig = {
	primary: {
		main: Primary.Light.Base
	},
	secondary: {
		main: Secondary.Light.Base
	}
}