import React from 'react'
import Text from '../Text'
import { Box, Divider, Stack } from '@mui/material'
import { convertDate } from '../../utils/dateUtils'

type InfoCellProps = {
	label: string
	value?: string
	displayDivider?: boolean
	dateOnly?: boolean
	onClick?: () => void
}

const InfoCell: React.FC<InfoCellProps> = (
	{
		label,
		value,
		displayDivider = true,
		dateOnly,
		onClick
	}) => {
	return (
		<Stack width="100%">
			{displayDivider && <Divider />}
			<Box paddingY="12px" display="flex" alignItems="center" justifyContent="space-between">
				<Text variant="subtitle1" color="textSecondary">{label}</Text>
				{value ?
					<Text sx={{ textAlign: 'end' }} variant="h6" color="textPrimary">{dateOnly ? convertDate(value) : value}</Text> :
					<a href="#" style={{ color: 'rgba(0, 0, 0, 0.87)' }} onClick={onClick}><Text variant="h6" color="textPrimary">Ouvrir</Text></a>
				}
			</Box>
		</Stack>
	)
}

export default InfoCell
