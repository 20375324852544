import React, { useContext, useMemo } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import App from './App'
import { colorConfig, typographyConfig } from '../config/theme'
import { ThemeContext } from '../config/context/ThemeContext'
import LoginPage from '../modules/login/LoginPage'
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import ServiceWorkerWrapper from '../modules/serviceWorker/ServiceWorkerWrapper'
import { APP_ROUTES } from './routesConstants'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider, frFR } from '@mui/x-date-pickers'
import frLocale from 'date-fns/locale/fr'
import { frFR as coreFrFR } from '@mui/material/locale'
import VisitProvider from '../modules/visit/services/VisitProvider'

const Root = () => {
	const { theme } = useContext(ThemeContext)

	const configTheme = useMemo(() => createTheme({
		typography: typographyConfig as TypographyOptions,
		palette: {
			mode: theme,
			...colorConfig
		}
	}, frFR, coreFrFR), [theme])

	return (
		<MUIThemeProvider theme={configTheme}>
			<ServiceWorkerWrapper>
				<VisitProvider>
					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
						<BrowserRouter>
							<Switch>
								<Route path={APP_ROUTES.LOGIN} component={LoginPage} />
								<Route path={APP_ROUTES.LOGOUT} component={LoginPage} />
								<Route path="/" component={App} />
								<Route path="*" component={App} />
							</Switch>
						</BrowserRouter>
					</LocalizationProvider>
				</VisitProvider>
			</ServiceWorkerWrapper>
		</MUIThemeProvider>
	)
}

export default Root
