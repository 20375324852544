import React, { Dispatch, SetStateAction, useContext } from 'react'
import { ThemeContext } from '../config/context/ThemeContext'
import { Box, Modal, Stack } from '@mui/material'
import CloseIcon from '@material-ui/icons/Close'
import Button from './Button'
import { useIntl } from 'react-intl'
import Text from './Text'

type PopinProps = {
	label?: string
	modalOpen: boolean
	setModalOpen: Dispatch<SetStateAction<boolean>>
}


const Popin: React.FC<PopinProps> = (
	{
		children,
		modalOpen,
		setModalOpen,
		label
	}) => {
	const intl = useIntl()
	const { themeValues } = useContext(ThemeContext)

	// i18n
	const cancelMessage = { id: 'global.buttons.cancel' }

	// Colors
	const bgColor = themeValues.Muted.BGPrimary
	const buttonTextColor = themeValues.Muted.SingleTone
	const borderColor = themeValues.Muted.Border
	const closeIconColor = themeValues.Muted.Content3

	return (
		<Modal open={modalOpen} onClose={() => setModalOpen(false)} sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
			<Stack
				direction="column"
				p="12px"
				borderRadius="12px"
				padding="12px"
				marginX="24px"
				bgcolor={bgColor}
				spacing={1}
			>
				<Stack direction="row" alignItems="center" justifyContent="space-between">
					<Box>
						{label && <Text variant="h5">{label}</Text>}
					</Box>
					<Button
						fullWidth={false}
						onClick={() => setModalOpen(false)}
						variant="outlined"
						sx={{ borderColor: 'transparent', color: closeIconColor }}
						label={<CloseIcon />}
					/>
				</Stack>
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					{children}
				</Box>
				<Button
					onClick={() => setModalOpen(false)}
					label={intl.formatMessage(cancelMessage)}
					sx={{ bgColor, border: `1px solid ${borderColor}`, color: buttonTextColor, marginTop: '12px' }}
				/>
			</Stack>
		</Modal>
	)
}

export default Popin