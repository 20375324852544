import React, { FC, useContext } from 'react'
import { Field } from 'react-final-form'
import ErrorMessage from './ErrorMessage'
import Text from './Text'
import { Stack, TextField } from '@mui/material'
import { useIntl } from 'react-intl'
import { ThemeContext } from '../config/context/ThemeContext'

type TextInputProps = {
	name: string
	label?: string
	variant?: 'outlined'
	type?: string
	validate?: any
	placeholder?: string
	multiline?: boolean
	required?: boolean
	defaultValue?: string
}

const TextInput: FC<TextInputProps> = (
	{
		name,
		label,
		variant = 'outlined',
		type = 'text',
		validate,
		placeholder,
		multiline,
		required,
		defaultValue
	}) => {
	const intl = useIntl()
	const { themeValues } = useContext(ThemeContext)

	const height = multiline ? 'auto' : '40px'

	// i18n
	const requiredMessage = { id: 'global.errors.required' }
	const optionalMessage = { id: 'form.optional' }

	const requiredCheck = (value: string) => (value ? undefined : intl.formatMessage(requiredMessage))

	const titleEnd = required ? <span style={{ color: themeValues.Secondary.Base }}> *</span> : <Text> ({intl.formatMessage(optionalMessage)})</Text>

	return (
		<Field name={name} validate={required ? requiredCheck : validate}>
			{({ input, meta }) => {
				if (defaultValue && !input.value) {
					input.onChange(defaultValue)
				}

				return (
					<Stack direction="column" spacing={1}>
						<Text variant="subtitle1">{label}{titleEnd}</Text>
						<TextField
							id={name}
							multiline={multiline}
							maxRows={4}
							variant={variant}
							color="primary"
							size="small"
							type={type}
							fullWidth
							value={meta.dirty ? input.value : defaultValue}
							onChange={input.onChange}
							placeholder={placeholder}
							InputProps={{ sx: { fontSize: '13px', fontWeight: 400, borderRadius: '8px', height } }}
						/>
						<ErrorMessage disable={!(meta.error && meta.touched)} error={meta.error} />
					</Stack>
				)
			}}
		</Field>
	)
}

export default TextInput