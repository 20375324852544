import React, { useEffect } from 'react'
import PageContainer from '../../../components/layout/PageContainer'
import Card from '../../../components/layout/Card'
import { Box, Stack } from '@mui/material'
import Text from '../../../components/Text'
import { APP_ROUTES } from '../../../containers/routesConstants'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'


const MentionLegal = () => {

	const intl = useIntl()
	const history = useHistory()

	// i18n
	const returnMessage = { id: 'global.buttons.returnHome' }

	useEffect(() => {

	}, [])

	return (
		<PageContainer
			mainButtonProps={{
				onClick: () => history.push(APP_ROUTES.HOME),
				label: intl.formatMessage(returnMessage)
			}}
		>
			<Card title="Propriété">
				<Stack alignItems="start" width="100%">
					<Box paddingY="12px" display="flex" alignItems="flex-start" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">Le présent site est la propriété de :</Text>
					</Box>
					<Stack direction="column" paddingY="12px" display="flex" alignItems="center" justifyContent="space-between">
						<Text variant="h6" color="textPrimary" sx={{ width: '100%' }}>MUST - Siège social</Text>
						<Text variant="h6" color="textPrimary" sx={{ width: '100%' }}>97, rue Haroun Tazieff</Text>
						<Text variant="h6" color="textPrimary" sx={{ width: '100%' }}>54320 MAXEVILLE</Text>
						<Text variant="h6" color="textPrimary" sx={{ width: '100%' }}>FRANCE</Text>
					</Stack>
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">La structure générale du site, les textes et les images, sont la propriété et sous la responsabilité éditoriale de MUST. Aucune reproduction, intégrale ou partielle, des textes ou des
							photos n'est autorisée sans l'acceptation préalable de MUST.</Text>
						<Text variant="h6" color="textPrimary">Le responsable de la publication est M. Claude BOCEK (président).</Text>
					</Stack>
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">Vous pouvez contacter MUST à l’adresse suivante <a href={'mailto:amandine.bezon@groupe-must.fr'}>amandine.bezon@groupe-must.fr</a></Text>
					</Stack>
				</Stack>
			</Card>
			<Card title="Conception, réalisation et hébergement">
				<Stack alignItems="start" width="100%">
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">Conception & Réalisation</Text>
						<Text variant="h6" color="textPrimary">IPSOSENSO, 1 rue Saint Georges, 75009 Paris, France.</Text>
					</Stack>
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">Hébergement</Text>
						<Text variant="h6" color="textPrimary">Idex Services, 148/152 Rte de la Reine, 92100 Boulogne-Billancourt, France</Text>
					</Stack>
				</Stack>
			</Card>
			<Card title="Crédits photos et illustrations">
				<Stack alignItems="start" width="100%">
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">Photos</Text>
						<Text variant="h6" color="textPrimary">LIbres de droits - MUST</Text>
					</Stack>
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">Illustrations</Text>
						<Text variant="h6" color="textPrimary">Story Set - Freepick compagny</Text>
					</Stack>
				</Stack>
			</Card>
			<Card title="Informatique et libertés">
				<Stack alignItems="start" width="100%">
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">Les informations nominatives recueillies à l'occasion d'une demande sur le site sont destinées à l'usage de MUST. Elles pourront donner lieu à exercice du droit d'accès et de
							rectification auprès des services de MUST dans les conditions prévues par la loi n° 78-17 du 06 Janvier 1978 relative à l'informatique, aux fichiers et aux libertés. Titularité des droits.</Text>
					</Stack>
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">Tous les éléments du présent site Internet (notamment les marques déposées, noms de domaine, les textes, graphismes, logos, sons, photos et animations ainsi que toutes formes graphiques
							et audiovisuelles) sont la propriété de MUST, à l'exclusion des éléments émanant de ses partenaires.</Text>
					</Stack>
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">Tous les droits de reproduction sont réservés, y compris pour les documents téléchargeables et les représentations textuelles, iconographiques ou photographiques. La reproduction, et/ou
							imitation, et/ou représentation, et/ou rediffusion de tout ou partie du site Internet sur un support électronique quel qu'il soit est formellement interdite sauf accord expresse.</Text>
					</Stack>
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">Les informations transmises au visiteur le sont uniquement à titre personnel, et en vue d'un usage strictement personnel. Le non-respect de ces dispositions est susceptible de constituer
							un acte de contrefaçon engageant les responsabilités civile et pénale de tout contrevenant.</Text>
					</Stack>
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">De même, les bases de données d'informations sont protégées au titre du droit applicable aux compilations de données. De sorte que toute extraction ou tentative d'extraction, fut-elle
							totale ou partielle des données contenues sur ce présent site est susceptible d'engager les responsabilités civile et pénale de tout contrevenant.</Text>
					</Stack>
				</Stack>
			</Card>
			<Card title="Protection des données personnelles">
				<Stack alignItems="start" width="100%">
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">Aucune donnée à caractère personnel n’est collectée ni diffusée à votre insu sur le site MUST. Conformément aux articles 38 et suivants de la loi n° 78-17 du 6 janvier 1978 relative à
							l’informatique, aux fichiers et aux libertés, toute personne peut s’opposer à la publication d’informations la concernant, en obtenir communication et, le cas échéant, rectification ou suppression de ces informations en
							s’adressant à MUST via la page 'Contact'</Text>
					</Stack>
				</Stack>
			</Card>
			<Card title="Liens hypertextes">
				<Stack alignItems="start" width="100%">
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">L'établissement de liens hypertextes vers le site MUST et la syndication de contenu vers le site peuvent être librement établis dès lors qu’ils ne sont pas utilisés à des fins
							commerciales ou publicitaires, sous réserve que la mention « Sources site internet MUST » ou toute mention équivalente soit indiquée clairement sur le lien ou à proximité de celui-ci.</Text>
					</Stack>
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">Sont naturellement exclus de cette autorisation les sites diffusant des informations à caractère illicite, violent, polémique, pornographique, xénophobe ou pouvant porter atteinte à la
							sensibilité du plus grand nombre.</Text>
					</Stack>
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">La syndication du contenu des flux RSS des sites sur un autre site ou blog est soumise aux mêmes conditions.</Text>
					</Stack>
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">MUST ne pourra en aucun cas être tenus pour responsables de la mise à disposition des sites partenaires qui font l'objet d'un lien hypertexte à partir du site
							http://www.must-multiservice.com et ne pourront supporter aucune responsabilité sur le contenu, les produits, les services, etc. disponibles sur ces sites ou à partir de ces sites.</Text>
					</Stack>
				</Stack>
			</Card>
			<Card title="Utilisation de cookies">
				<Stack alignItems="start" width="100%">
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">L’outil statistique de mesure de la fréquentation du site est, de manière générale, basé sur la seule analyse des 'logs' de connexions sur les serveurs. Toutefois, certaines pages peuvent
							contenir un marqueur témoin « cookie ».</Text>
					</Stack>
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">Pour toute question en lien avec la collecte et le traitement de vos Données opérés par EGMI, vous pouvez contacter par courrier électronique notre délégué à la protection des données
							(DPO) à l’adresse suivante : <a href={'mailto:dpo@idex.fr'}>dpo@idex.fr</a>.</Text>
					</Stack>
				</Stack>
			</Card>
			<Card title="Responsabilités">
				<Stack alignItems="start" width="100%">
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">L'internaute utilise le site MUST à ses seuls risques. MUST ne pourra être tenu responsable des dommages directs ou indirects, et notamment des préjudices matériels, pertes de données ou
							de programmes, préjudices financiers, résultant de l'accès ou de l'utilisation au site MUST ou de tous sites qui lui sont liés.</Text>
					</Stack>
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">Illustrations</Text>
						<Text variant="h6" color="textPrimary">Story Set - Freepick compagny</Text>
					</Stack>
				</Stack>
			</Card>
			<Card title="Crédits photos et illustrations">
				<Stack alignItems="start" width="100%">
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">Photos</Text>
						<Text variant="h6" color="textPrimary">LIbres de droits - MUST</Text>
					</Stack>
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">Le contenu du site est présenté sans aucune garantie de quelque nature que ce soit. Toutes les informations données sur le site MUST ne sont données qu'à titre indicatif.</Text>
					</Stack>
				</Stack>
			</Card>
			<Card title="Droits applicables">
				<Stack alignItems="start" width="100%">
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">Le présent site est soumis aux dispositions du droit interne français. La langue par défaut du site est la langue française. D'autres langues peuvent être néanmoins proposées à titre
							informatif. En cas de litige, seul le tribunal de grande instance de Reims est compétent.</Text>
					</Stack>
				</Stack>
			</Card>
			<Card title="Mise à jour du site">
				<Stack alignItems="start" width="100%">
					<Stack direction="column" paddingY="12px" display="flex" justifyContent="space-between">
						<Text variant="h6" color="textPrimary">Le site est mis à jour régulièrement par MUST. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s'imposent néanmoins à l'internaute qui est invité à s'y
							référer le plus souvent possible afin d'en prendre connaissance.</Text>
					</Stack>
				</Stack>
			</Card>
		</PageContainer>
	)
}

export default MentionLegal
