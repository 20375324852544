import React, { useContext, useMemo, useState } from 'react'
import PageContainer from '../../../components/layout/PageContainer'
import { useIntl } from 'react-intl'
import { CodeContext } from '../services/context/CodeContext'
import Scanner from '../components/Scanner'
import { useHistory } from 'react-router'
import { APP_ROUTES } from '../../../containers/routesConstants'
import Card from '../../../components/layout/Card'
import { Stack } from '@mui/material'
import { EquipmentFieldNames } from '../../addingSpace/services/addingSpaceConstants'
import InfoCell from '../../../components/layout/InfoCell'
import Text from '../../../components/Text'
import { convertDate } from '../../../utils/dateUtils'
import { ThemeContext } from '../../../config/context/ThemeContext'

const ClientEntityFoundPage = () => {
	const intl = useIntl()
	const history = useHistory()
	const { houseInfoOptions, equipmentInfo, equipmentInfoOptions, houseInfo } = useContext(CodeContext)
	const { themeValues } = useContext(ThemeContext)

	const [openScanner, setOpenScanner] = useState<boolean>(false)

	// i18n
	const returnMessage = { id: 'global.buttons.goBack' }
	const scanQrCodeMessage = { id: 'global.buttons.scanQrCode' }
	const equipmentInfoMessage = { id: 'intervention.subtitle.equipment' }
	const infoMessage = { id: 'visit.subtitle.info' }
	const linkedEquipmentMessage = { id: 'visit.subtitle.linkedEquipment' }
	const typeMessage = { id: 'visit.equipmentInfo.type' }
	const brandMessage = { id: 'visit.equipmentInfo.brand' }
	const visitDateMessage = { id: 'visit.equipmentInfo.visitDate' }
	const latestWorksInterventionDateMessage = { id: 'visit.equipmentInfo.latestWorksInterventionDate' }
	const latestCurativeInterventionDateMessage = { id: 'visit.equipmentInfo.latestCurativeInterventionDate' }


	const isEquipmentDocumentsEmpty = useMemo(() => equipmentInfo ? (equipmentInfoOptions.filter(element => element.key === intl.formatMessage({ id: 'visit.equipmentInfo.documents' }))[0].value as MustDocument[]).length === 0 : false, [equipmentInfo, equipmentInfoOptions, intl])

	return (
		<PageContainer
			mainButtonProps={{
				onClick: () => setOpenScanner(true),
				label: intl.formatMessage(scanQrCodeMessage)
			}}
			secondaryButtonProps={{
				onClick: () => history.push(APP_ROUTES.HOME),
				label: intl.formatMessage(returnMessage)
			}}
		>
			{!!equipmentInfo ?
				(<Card title={intl.formatMessage(equipmentInfoMessage)}>
					{equipmentInfoOptions &&
						<Stack width="100%">
							{equipmentInfoOptions.filter(element => element.key !== intl.formatMessage({ id: 'visit.equipmentInfo.documents' })
								&& element.key !== intl.formatMessage({ id: 'visit.equipmentInfo.id_type' })
								&& element.key !== intl.formatMessage({ id: 'visit.equipmentInfo.id_brand' })
								&& element.key !== intl.formatMessage({ id: 'visit.equipmentInfo.id_model' })
							).map((info, index) => {
								const isDateOnly =
									(info.key === intl.formatMessage({ id: `visit.equipmentInfo.${EquipmentFieldNames.COMMISSIONING_DATE}` }) ||
										info.key === intl.formatMessage({ id: 'visit.equipmentInfo.visitDate' }) ||
										info.key === intl.formatMessage({ id: 'visit.equipmentInfo.latestWorksInterventionDate' }) ||
										info.key === intl.formatMessage({ id: 'visit.equipmentInfo.latestCurativeInterventionDate' })
									)
								return (
									<InfoCell
										displayDivider={index !== 0}
										dateOnly={isDateOnly}
										label={info.key}
										value={info.value}
										key={index}
									/>
								)
							})}
							{!isEquipmentDocumentsEmpty && (equipmentInfoOptions.filter(element => element.key === intl.formatMessage({ id: 'visit.equipmentInfo.documents' }))[0].value as MustDocument[]).map((document, index) => {
								const docUrl = document.url
								return (
									<InfoCell
										label={document.name}
										onClick={() => {
											const newWindow = window.open(docUrl, '_blank', 'noopener,noreferrer')
											if (newWindow) newWindow.opener = null
										}}
										key={index}
									/>
								)
							})}
						</Stack>
					}
				</Card>) :
				(
					<Stack>
						<Card title={intl.formatMessage(infoMessage)}>
							<Stack width="100%">
								{houseInfoOptions.map((info, index) => (
									<InfoCell key={`${info.key}-${info.value}`} displayDivider={index !== 0} label={info.key} value={info.value} />
								))}
							</Stack>
						</Card>
						<Card title={intl.formatMessage(linkedEquipmentMessage)}>
							{houseInfo && houseInfo.equipments.map((equipment) => (
								<Stack width="100%" borderRadius="8px" border={`1px solid ${themeValues.Muted.Border}`} mb="12px">
									<Stack px="12px" direction="row" alignItems="center" justifyContent="space-between">
										<Text variant="subtitle1" color="textSecondary">{intl.formatMessage(typeMessage)}</Text>
										<Text sx={{ textAlign: 'end' }} variant="h6" color="textPrimary">{equipment.type}</Text>
									</Stack>
									<Stack px="12px" direction="row" alignItems="center" justifyContent="space-between">
										<Text variant="subtitle1" color="textSecondary">{intl.formatMessage(brandMessage)}</Text>
										<Text sx={{ textAlign: 'end' }} variant="h6" color="textPrimary">{equipment.brand}</Text>
									</Stack>
									{equipment.visitDate &&
										<Stack px="12px" direction="row" alignItems="center" justifyContent="space-between">
											<Text variant="subtitle1" color="textSecondary">{intl.formatMessage(visitDateMessage)}</Text>
											<Text sx={{ textAlign: 'end' }} variant="h6" color="textPrimary">{convertDate(equipment.visitDate)}</Text>
										</Stack>
									}
									{equipment.latestCurativeInterventionDate &&
										<Stack px="12px" direction="row" alignItems="center" justifyContent="space-between">
											<Text variant="subtitle1" color="textSecondary">{intl.formatMessage(latestCurativeInterventionDateMessage)}</Text>
											<Text sx={{ textAlign: 'end' }} variant="h6" color="textPrimary">{convertDate(equipment.latestCurativeInterventionDate)}</Text>
										</Stack>
									}
									{equipment.latestWorksInterventionDate &&
										<Stack px="12px" direction="row" alignItems="center" justifyContent="space-between">
											<Text variant="subtitle1" color="textSecondary">{intl.formatMessage(latestWorksInterventionDateMessage)}</Text>
											<Text sx={{ textAlign: 'end' }} variant="h6" color="textPrimary">{convertDate(equipment.latestWorksInterventionDate)}</Text>
										</Stack>
									}
								</Stack>
							))}
						</Card>
					</Stack>
				)
			}
			<Scanner openModal={openScanner} setOpenModal={setOpenScanner} />
		</PageContainer>
	)
}

export default ClientEntityFoundPage
