import React, { FC, useContext } from 'react'
import { Box } from '@material-ui/core'
import { ThemeContext } from '../../config/context/ThemeContext'
import Text from '../Text'
import { Divider, Stack } from '@mui/material'
import { Close } from '@mui/icons-material'

type CardProps = {
	title?: string
	height?: string | number
	center?: boolean
	mainTitle?: string
	contentPadding?: string
}

const Card: FC<CardProps> = (
	{
		children,
		title,
		height,
		center,
		mainTitle,
		contentPadding = '0 20px 20px 20px'
	}) => {
	const { themeValues } = useContext(ThemeContext)

	// Colors
	const bgPrimaryColor = themeValues.Muted.BGPrimary
	const iconColor = themeValues.Secondary.Hover

	return (
		<Stack
			bgcolor={bgPrimaryColor}
			justifyContent={center ? 'center' : 'flex-start'}
			minHeight={height}
			sx={{
				alignItems: 'center',
				margin: '0 20px 20px 20px',
				borderRadius: '12px',
				boxShadow: '0px 14px 17px rgba(0, 0, 0, 0.04), 0px 5.84887px 7.10219px rgba(0, 0, 0, 0.0287542), 0px 3.12708px 3.79717px rgba(0, 0, 0, 0.0238443), 0px 1.75302px 2.12866px rgba(0, 0, 0, 0.02), 0px 0.931014px 1.13052px rgba(0, 0, 0, 0.0161557), 0px 0.387416px 0.470434px rgba(0, 0, 0, 0.0112458)'
			}}
		>
			{title && (
				<Stack padding="12px 20px" width="100%" direction="column">
					{mainTitle &&
						<Stack my="20px" spacing={2} direction="row" alignItems="center" alignSelf="center">
							<Text variant="h5">{mainTitle}</Text>
							<Close sx={{ color: iconColor }} />
						</Stack>
					}
					<Box padding="0px 20px 12px 20px">
						<Text variant="caption">{title.toUpperCase()}</Text>
					</Box>
					<Divider />
				</Stack>
			)}
			<Stack width="100%" p={contentPadding} boxSizing="border-box" alignItems="center" justifyContent="center">
				{children}
			</Stack>
		</Stack>
	)
}

export default Card