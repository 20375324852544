import { createContext, Dispatch, SetStateAction } from 'react'

type CodeContextProps = {
	houseInfo?: HouseInfo
	houseInfoOptions: InfoDataCell[]
	setHouseInfo: Dispatch<SetStateAction<HouseInfo | undefined>>
	spaceInfo?: Record<string, any>
	setSpaceInfo: Dispatch<SetStateAction<Record<string, any> | undefined>>
	code?: string
	setCode: Dispatch<SetStateAction<string | undefined>>
	equipmentInfo?: EquipmentInfo
	equipmentInfoOptions: InfoDataCell[]
	setEquipmentInfo: Dispatch<SetStateAction<EquipmentInfo | undefined>>
	equipmentDataInfo?: Record<string, any>
	setEquipmentDataInfo: Dispatch<SetStateAction<Record<string, any> | undefined>>
}

const context: CodeContextProps = {
	houseInfo: undefined,
	houseInfoOptions: [],
	setHouseInfo: () => {
	},
	spaceInfo: undefined,
	setSpaceInfo: () => {
	},
	code: undefined,
	setCode: () => {
	},
	equipmentInfo: undefined,
	equipmentInfoOptions: [],
	setEquipmentInfo: () => {
	},
	equipmentDataInfo: undefined,
	setEquipmentDataInfo: () => {
	}
}

export const CodeContext = createContext(context)