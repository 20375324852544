import { fetchFactory } from '../../../utils/fetchFactory'

export const importZone = () => fetchFactory('/api/admin/import/zone')

export const importEquipement = () => fetchFactory('/api/admin/import/equipement')

export const importUser = () => fetchFactory('/api/admin/import/user')

export const importBailleur = () => fetchFactory('/api/admin/import/bailleur')

export const generationMdp = () => fetchFactory('/api/admin/import/initUser')
